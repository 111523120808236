

export const logout = async () => {
  const link = sessionStorage.getItem('homeLink') || "https://emr.heartlandcardiovascular.com.ng/"
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('USER_INFO');
  sessionStorage.removeItem('homeLink');
 
  window.location.assign(link || '/');
};
